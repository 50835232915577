import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import PortableText from '../components/portableText'

import '../assets/css/blog.css'
import LayoutWhite from 'components/LayoutWhite/index'
import { format } from 'date-fns'

export default function Blog() {
  const data = useStaticQuery(graphql`
    fragment SanityImage on SanityMainImage {
      crop {
        _key
        _type
        top
        bottom
        left
        right
      }
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
      }
    }
    {
      allSanityPost(sort: { order: DESC, fields: publishedAt }) {
        edges {
          node {
            title
            slug {
              current
            }
            publishedAt
            mainImage {
              ...SanityImage
              alt
            }
            _rawExcerpt(resolveReferences: { maxDepth: 5 })
            _rawBody(resolveReferences: { maxDepth: 5 })
          }
        }
      }
    }
  `)
  const allSanityPost = (data || {}).allSanityPost

  return (
    <LayoutWhite>
      <div>
        {' '}
        {allSanityPost.edges
          .filter((post) => post.publishedAt !== null)
          .map(({ node }) => {
            console.log('NODE', node)
            return (
              <div
                className="individual-blog container mt-4 mb-6 p-4"
                key={node.id}
              >
                {' '}
                <h1>
                  {' '}
                  <Link to={'/blog/' + node.slug.current}>
                    {node.title}
                  </Link>{' '}
                </h1>{' '}
                <div className="pb-2">
                  <img
                    src={imageUrlFor(buildImageObj(node.mainImage))
                      .width(300)
                      //.height(Math.floor((9 / 16) * 1200))
                      //.fit('crop')
                      .auto('format')
                      .url()}
                    alt={node.mainImage.alt}
                  />
                </div>
                <h4 className="text-gray-dark">
                  {format(new Date(node.publishedAt), 'dd MMMM yyyy')}
                </h4>{' '}
                <p className="text-gray">
                  <PortableText blocks={node._rawExcerpt} />
                </p>{' '}
              </div>
            )
          })}{' '}
      </div>
    </LayoutWhite>
  )
}
